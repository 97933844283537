<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="ds-app-row")
    td.ds-app-select
      v-checkbox(
        :disabled="!isSelectedSchoolFilter || hasTransaction"
        hide-details
        :value="isSelected"
        :key="index"
        @change="select"
      ).mt-0
    td.ds-app-index.pl-1
      span {{ positionInList }}
    td.ds-app-name {{ item.driving_school.name }}
    td.ds-app-appID {{ item.application_id }}
    td.ds-app-candidate {{ item.candidate.first_name }} {{ item.candidate.last_name }}
    td.ds-app-course-type {{ item.course_type }}
    td.ds-app-amount €{{ item.amount }}
    td.ds-app-created-at {{ item.created_at | convertToDefaultDateHourFormat }}
    td.ds-app-paid(@click="linkToTransaction")
      span.pr-1 {{ item.transaction ? 'Yes' : 'No' }}
      ico-arrow-link(v-if="item.transaction")
</template>

<script>
import { convertToDefaultDateHourFormat } from '@/util'

export default {
  props: {
    isSelected: Boolean,
    expand: Function,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    loading: Boolean,
  },

  filters: {
    convertToDefaultDateHourFormat
  },

  computed: {
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size
    },

    isSelectedSchoolFilter() {
      return !!(this.$route.query && this.$route.query.driving_school)
    },

    hasTransaction() {
      return !!(this.item && this.item.transaction)
    },
  },

  methods: {
    linkToTransaction() {
      this.$emit('click:link', this.item)
      this.$scrollTo('html')
    }
  },

  components: {
    icoArrowLink: () => import('@/assets/img/ui/crm/IcoArrowLink.vue')
  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.ds-app-row {
  .ds-app- {
    &select div {
      padding-left: 12px;
    }

    &paid {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .25s ease-in-out;

      &:hover {
        svg {
          transform: rotate(45deg) scale(1.2);
          transition: all .25s ease-in-out;
        }
      }
    }
  }
}

</style>

<style lang="scss">
.ds-app-id {
  padding-left: 4px !important;
}
</style>
